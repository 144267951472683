





import { Component, Vue } from 'vue-property-decorator'

@Component
export default class GoToPayment extends Vue {
  private get orderUUID() {
    return this.$route.params.orderUUID
  }

  private mounted() {
    this.$router.replace({
      name: 'master.payment.order',
      params: {
        orderUUID: this.orderUUID,
      },
    })
  }
}
